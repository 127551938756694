import cattyBR from './img/catty2.png';
import './App.css';
import './AppTabletAndMobiles.css';
import { useState } from 'react'
import Home from './components/Home'
import Servizi from './components/Servizi'
import AreaPrivata from './components/AreaPrivata'
import Assistenza from './components/Assistenza'
import Chi from './components/Chi'
import Plus from './components/Plus'
import Voip from './components/Voip'

function App() {

  const page = {
    Home: 'home',
    Servizi : 'servizi',
    AreaPrivata : 'areaprivata',
    Chi : 'chi',
    Plus : 'plus',
    Voip:'voip',
    Assistenza:'assistenza',
  }
  
  const [ activePage, setActivePage ] = useState(page.Home)

  return (
    <div className="app">
      <header>
          <h1 className="homeTitle" alt="Titolo del sito web di Flavio Mazzini Emme Effe Informatica ad Arcidosso" onClick={()=> setActivePage(page.Home)}>EMME-EFFE</h1> <span>Informatica</span>
      </header>
      <nav>
        <div className={`navButtons ${activePage===page.Servizi?'navButtonsActive':''}`} onClick={()=> setActivePage(page.Servizi)}>SERVIZI</div> 
        <div className={`navButtons ${activePage===page.Chi?'navButtonsActive':''}`} onClick={()=> setActivePage(page.Chi)}>CHI E DOVE</div>
        <div className={`navButtons ${activePage===page.Voip?'navButtonsActive':''}`} onClick={()=> setActivePage(page.Voip)}>VOIP</div>
        <div className={`navButtons ${activePage===page.Assistenza?'navButtonsActive':''}`} onClick={()=> setActivePage(page.Assistenza)}>ASSISTENZA</div>
        <div className={`navButtons`}  onClick={()=>window.open('https://www.emme-effe.it/sw','new_window')}>AREA PRIVATA</div>
        {/* <div className={`navButtons ${activePage===page.AreaPrivata?'navButtonsActive':''}`} onClick={()=> setActivePage(page.AreaPrivata)}>AREA PRIVATA</div> */}
      </nav>
      {activePage=== page.Home&&<Home></Home>}
      {activePage=== page.Servizi&&<Servizi></Servizi>}
      {activePage=== page.Chi&&<Chi></Chi>}
      {activePage=== page.Assistenza&&<Assistenza></Assistenza>}
     {/*  {activePage=== page.AreaPrivata&&<AreaPrivata></AreaPrivata>} */}
      {activePage=== page.Voip&&<Voip></Voip>}
      {activePage=== page.Plus&&<Plus></Plus>}
      <footer > 
      <span onClick={()=> activePage===page.Plus?setActivePage(page.Home):setActivePage(page.Plus)}><img alt="immagine di un gatto" id="logoGatto" src={cattyBR}></img></span> 
      </footer>
    </div>
  );
}

export default App;
