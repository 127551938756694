import React, { useState, useEffect } from "react";

function Assistenza() {
    return (
        <div className='content'>
            <div className='assistenzaContainer'>
                <a href='https://www.emme-effe.it/appAssistenza/TeamViewerQS.exe'>
                    <div className='assistenzaContent'>
                        <span>
                            Assistimi su <b>Windows</b>{" "}
                        </span>
                        <br />
                        <svg
                            width='48px'
                            height='48px'
                            strokeWidth='1.5'
                            viewBox='0 0 24 24'
                            fill='white'>
                            <path
                                d='M4 16.986V7.014a.6.6 0 01.507-.593l14.8-2.313a.6.6 0 01.693.593v14.598a.6.6 0 01-.693.593l-14.8-2.313A.6.6 0 014 16.986zM4 12h16M10.5 5.5v13'
                                stroke='#000000'
                                strokeWidth='1.5'></path>
                        </svg>
                    </div>
                </a>
                <a href='https://www.emme-effe.it/appAssistenza/TeamViewerQS.dmg'>
                    <div className='assistenzaContent'>
                        <span>
                            Assistimi su <b>Mac</b>
                        </span>
                        <br />
                        <svg
                            width='48px'
                            height='48px'
                            strokeWidth='1.5'
                            viewBox='0 0 24 24'
                            fill='white'>
                            <path
                                d='M16 2c.363 2.18-1.912 3.83-3.184 4.571-.375.219-.799-.06-.734-.489C12.299 4.64 13.094 2 16 2z'
                                stroke='#000000'
                                strokeWidth='1.5'></path>
                            <path
                                d='M9 6.5c.897 0 1.69.2 2.294.42.762.279 1.65.279 2.412 0A6.73 6.73 0 0116 6.5c1.085 0 2.465.589 3.5 1.767C16 11 17 15.5 20.269 16.692c-1.044 2.867-3.028 4.808-4.77 4.808-1.5 0-1.499-.7-2.999-.7s-1.5.7-3 .7c-2.5 0-5.5-4-5.5-9 0-4 3-6 5-6z'
                                stroke='#000000'
                                strokeWidth='1.5'></path>
                        </svg>
                    </div>
                </a>
                <a href='https://www.emme-effe.it/appAssistenza/TeamViewer.apk'>
                    <div className='assistenzaContent'>
                        <span>
                            Assistimi su <b>Android</b>
                        </span>
                        <br />
                        <svg height='48' width='48' fill='white'>
                            <path d='M2 36.05q.4-5.3 3.25-9.825Q8.1 21.7 12.8 19.05L9.05 12.6q-.15-.45-.025-.9t.525-.7q.45-.25.975-.1.525.15.775.6l3.7 6.35Q19.3 16 24 16t9 1.85l3.75-6.35q.25-.45.775-.6.525-.15.975.1.4.25.575.725.175.475-.075.875l-3.8 6.45q4.7 2.65 7.55 7.175Q45.6 30.75 46 36.05Zm12-5.5q1 0 1.75-.75t.75-1.75q0-1-.75-1.75T14 25.55q-1 0-1.75.75t-.75 1.75q0 1 .75 1.75t1.75.75Zm20 0q1 0 1.75-.75t.75-1.75q0-1-.75-1.75T34 25.55q-1 0-1.75.75t-.75 1.75q0 1 .75 1.75t1.75.75Z' />
                        </svg>
                    </div>
                </a>
                <a href='https://apps.apple.com/us/app/teamviewer/id692035811'>
                    <div className='assistenzaContent'>
                        <span>
                            Assistimi su <b>iOS</b>
                        </span>
                        <br />
                        <svg height='48' width='48' fill='white'>
                            <path d='M13 46q-1.2 0-2.1-.9-.9-.9-.9-2.1V5q0-1.2.9-2.1.9-.9 2.1-.9h22q1.2 0 2.1.9.9.9.9 2.1v38q0 1.2-.9 2.1-.9.9-2.1.9Zm0-7.5V43h22v-4.5Zm11 3.75q.65 0 1.075-.425.425-.425.425-1.075 0-.65-.425-1.075-.425-.425-1.075-.425-.65 0-1.075.425-.425.425-.425 1.075 0 .65.425 1.075.425.425 1.075.425ZM13 35.5h22v-26H13Zm0-29h22V5H13Zm0 32V43Zm0-32V5v1.5Z' />
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Assistenza;
