import React from "react";
import logo1 from "../img/logo1.png";
/* import logo2 from '../img/logo2.png'; */

function Home() {
    return (
        <div className='homeContent' alt='Benvenuti sul sito di Flavio Mazzini' >
            <img src={logo1} alt='Tastiera' />
        </div>
    );
}

export default Home;
