import { useState } from "react";
import React from "react";

function Voip() {
    return (
        <div className='content' style={{ marginBottom: "0" }}>
            <div className='contentVoip'>
                <div className='titleVoip'>CENTRALINO TELEFONICO VOIP MF 4.0/4.0 CLOUD</div>
                <div className='subtitleVoip'>
                    EMME-EFFE ha creato una soluzione per trasformare la comunicazione telefonica in
                    convenienza e risparmio per la tua azienda.
                </div>
                <div className='textContainerVoip'>
                    <h4>Riduzione dei costi delle telefonate:</h4>
                    <p>
                        Riduzione dei costi sulla telefonia: Il principale vantaggio è la riduzione
                        dei costi sulle telefonate, soprattutto per le chiamate nazionali ed
                        internazionali. Le tariffe dell'operatore VoIP consentono un risparmio sulle
                        chiamate dal 40% al 120% rispetto agli operatori di telefonia mobile
                        italiani, grazie alle seguenti caratteristiche:
                        <ul style={{ margin: "1%" }}>
                            <li> - Fatturazione basata sul tempo effettivo di chiamata </li>
                            <li> - Nessuno scatto alla risposta</li>
                            <li>
                                - Nessuna differenza tariffaria tra telefonate urbane e interurbane
                            </li>
                            <li>- Chiamate a costo zero fra diverse sedi della stessa Azienda;</li>
                            <li>
                                - Chiamate a costo zero fra i Clienti dello stesso provider VoIP.
                            </li>
                        </ul>
                    </p>
                    {/* /////////////////// */}
                    <h4>Riduzione dei costi del centralino </h4>
                    <p>
                        Il Centralino VoIP MF essendo prodotto e venduto direttamente dalla
                        Emme-Effe, ha un costo notevolmente inferiore (circa il 40% in meno) dei
                        centralini VoIP oggi sul mercato.
                    </p>
                    {/* /////////////////// */}
                    <h4>Unico cablaggio per i nuovi impianti</h4>
                    <p>
                        I clienti che aprono nuove filiali o uffici possono evitare il cablaggio
                        delle tradizionali reti telefoniche a favore di un unico cablaggio di rete,
                        aumentando così i risparmi sui costi di installazione.
                    </p>
                    {/* /////////////////// */}
                    <h4>Team virtuali</h4>
                    <p>
                        La comunicazione integrata permette a persone dislocate su sedi diverse di
                        lavorare insieme come fossero nello stesso ufficio con notevole risparmio di
                        tempo e di costi.
                    </p>
                    {/* /////////////////// */}
                    <h4>Portabilità del numero</h4>
                    <p>
                        Il numero di telefono non è più legato alla sede fisica: il Cliente potrà
                        liberamente decidere di spostare tale numero da una sede all'altra,
                        semplicemente spostando fisicamente il telefono ove il numero è configurato
                        (seppur in accordo con il Piano Telefonico Nazionale che prevede l'utilizzo
                        delle numerazioni geografiche dal solo distretto di appartenenza del
                        prefisso). Una volta ricollegato il telefono alla rete internet, questo si
                        connetterà al centralino e sarà pronto per essere nuovamente raggiungibile
                        in pochi secondi.
                    </p>
                    {/* /////////////////// */}
                    <h4>Qualità vocale variabile</h4>
                    <p>
                        La qualità della riproduzione vocale può essere modificata a seconda delle
                        esigenze.
                    </p>
                    {/* /////////////////// */}
                    <h4>Aumento della privacy</h4>
                    <p>
                        Le chiamate tramite VoIP sono più sicure delle normali chiamate, anche se
                        non vengono criptate o cifrate. Per riuscire ad intercettare una
                        comunicazione VoIP si deve aver accesso agli apparati che costituiscono la
                        rete VoIP e/o la Rete Internet. L'autenticazione che invece permette di
                        identificare chi effetua le chiamate è protetta tramite un protocollo di
                        hashing, molto difficile da ingannare.
                    </p>
                    {/* /////////////////// */}
                    <h4>In cosa consiste il VoIP?</h4>
                    <p>
                        Per VoIP (Voice Over Internet Protocol o Voce Tramite Protocollo Internet)
                        si intende una tecnologia capace di convertire il segnale della voce in un
                        segnale digitale. Questo può viaggiare compresso in "pacchetti" sulla rete
                        dati, utilizzando un protocollo internet e subendo poi, il processo inverso
                        per riconvertirlo in segnale vocale analogico (consente quindi di effettuare
                        e ricevere telefonate utilizzando la Rete Internet anziché la Rete
                        Telefonica Pubblica).
                    </p>
                    <h4>Indicazioni tecniche</h4>
                    <p>
                        Per capire meglio il funzionamento del VoIP, è necessario sapere che questo
                        utilizza un protocollo base (TCP/IP) e un convertitore analogico-digitale
                        (ADC) che "traduce" la nostra voce nel "linguaggio di internet", ovvero la
                        traduce in dati. Questi vengono suddivisi in "pacchetti", riassemblati in
                        ordine cronologico e riconvertiti, così, in voce da un DAC (convertitore
                        digitale-analogico).
                        <h4>Cosa permette di fare</h4>
                        Questa tecnologia permette di telefonare a costi molto bassi in qualsiasi
                        parte del mondo senza differenze geografiche. La qualità della telefonata è
                        paragonabile a quella di una normale linea telefonica; basta solo disporre
                        di una connessione internet a banda larga (ADSL o HDSL) con almeno 32 kbps
                        di banda garantita. Mentre per le chiamate interne o esterne tramite le
                        linee pstn è sufficiente una rete lan.
                        <h4>Usiamo il VOIP ma non ce ne accorgiamo </h4>
                        Da diversi anni ormai tutti i principali gestori telefonici nazionali
                        utilizzano il VOIP per instradare le comunicazioni sulle proprie centrali
                        telefoniche tradizionali. Anche se per telefonare utilizziamo un vecchio
                        telefono a disco (o un qualsiasi telefono analogico) a monte del nostro cavo
                        telefonico il nostro gestore molto probabilmente trasmetterà la chiamata
                        tramite VOIP. Non ci accorgiamo che stiamo utilizzando una nuova tecnologia
                        ma in realtà da diversi anni la nostra voce viaggia su rete Voice Over IP.
                        Funziona con tutti i telefoni, videotelefoni SIP e apparati Voip a standard
                        SIP e con provider/carrier Inoltre possiamo usare i nostri smartphone come
                        interni sia audio che video che offrono servizi di telefonia SIP.
                    </p>
                    {/* /////////////////// */}
                    <h4>Passi da fare prima di poter usufruire del VoIP </h4>
                    <p>
                        Un utente che vuole disporre della tecnologia VoIP deve avere
                        necessariamente un allaccio INTERNET di tipo XDSL o AIR (ovviamente a banda
                        larga) o possedere un router al quale si collegherà l'apparecchio
                        telefonico. Il requisito di banda è di 128 Kbit/sec bidirezionali minimi, e
                        quello di latenza è di 150 ms di RTT.
                    </p>
                    {/* /////////////////// */}
                    <h4>Personalizzazioni Esclusive</h4>
                    <p>
                        <ul style={{ margin: "1%" }}>
                            <li>
                                - Vuoi essere sempre raggiungibile? Decidi di far squillare
                                contemporaneamente l’interno dell’ufficio e i tuoi telefoni esterni.
                            </li>
                            <li>
                                - Chiami da qualsiasi telefono, fisso o mobile, presentandoti con il
                                numero di centralino dell’ufficio.
                            </li>
                            <li>- Ricevi in mobilità le chiamate dirette al centralino.</li>
                            <li>- Lavori facilmente anche a distanza.</li>
                            <li>- Gestisci in sicurezza il centralino da Internet.</li>
                            <li>
                                - Gestisci le chiamate verso il numero di centralino del tuo ufficio
                                secondo la modalità più adatta alle tue esigenze.
                            </li>
                            <li>
                                - Puoi personalizzare tutti i servizi di centralino in autonomia
                                attraverso un’interfaccia web di facile e immediato utilizzo.
                            </li>
                            <li>
                                - Rubrica telefonica Integrata con Microsoft Outlook con possibilità
                                di chiamata
                            </li>
                            <li>
                                - Possibiltà di blocco delle chiamate anonime (Nessun Gestore vi
                                offre questo servizio!!!!)
                            </li>
                            <li>- Numero linee esterne illimitate</li>
                            <li>
                                - Numero interni illimitato Casella vocale/segreteria su ogni
                                interno con ricezione messaggio audio immediato via mail Invio di
                                fax direttamente dal pc
                            </li>
                            <li>
                                - Ricezione fax-pdf in posta elettronica Gruppi di squillo
                                personalizzabili
                            </li>
                            <li>
                                - Storico del traffico con dettaglio chiamate e statistiche avanzate
                                (CDR - Call Detail Records)
                            </li>
                            <li>- Agenda con Rubrica e gestione chiamate ed appuntamenti</li>
                            <li>
                                - Possibilità di utilizzo dei telefoni in sedi remote Videochiamate
                                e
                            </li>

                            <li>- Videomessaggi (anche su 3g)</li>
                            <li>
                                - Squillo di chiamata differenziato fra chiamate esterne e interne
                            </li>
                            <li>- Musica di attesa configurabile (Mp3, Radio in streaming etc)</li>
                            <li>- Registrazione delle telefonate</li>
                            <li>- Modalità assente</li>
                            <li>- Impegno linea senza anteporre lo zero o altre cifre</li>
                            <li>- Possibilità di effettuare chiamate anonime</li>
                            <li>
                                - Blocco chiamate in uscita per tipologia (nazionali, cellulari,
                                internazionali)
                            </li>
                            <li>
                                - Risponditore automatico (IVR - Interactive Voice Response) con
                                chiamata diretta ad un operatore
                            </li>
                            <li>- Modalità giorno e notte</li>
                            <li>- Possibilità di collegamento con videocitofoni e apricancello</li>
                            <li>- Gestione delle code</li>
                            <li>
                                - Condizione temporale (cambio configurazione in periodi dell’anno
                                es. ferie)
                            </li>
                            <li>
                                - Possibilità di utilizzo di smartphone come telefoni o
                                videotelefoni interni e esterni
                            </li>
                            <li>- Trasferimento di chiamata (cieco e con annuncio)</li>
                            <li>
                                - Instradamento automatico su gateway Gsm per roaming aziendale
                                (abbattimento costi di chiamate ai cellulari)
                            </li>
                            <li>
                                - Selezione operatore automatica in uscita per telefonate ai numeri
                                fissi o cellulari
                            </li>
                            <li>
                                - Installazione su rete lan esistente (non necessita di passaggio
                                cavi)
                            </li>
                            <li>- Possibilità di acquisto e/o noleggio</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Voip;
