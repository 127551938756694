import React from "react";

function Chi() {
    return (
        <div className='content'>
            <div>
                <h3 className='titleChi'>Emme-Effe Informatica di Mazzini Flavio</h3>
                <p className='textChi'>Viale Davide Lazzaretti, 28</p>
                <p className='textChi'>58031 Arcidosso (GR)</p>
                {/* <div> -&gt;Ottieni Indicazioni Stradali</div> */}
                <br />
                <div className='gridNumbers'>
                    <b>Tel</b>
                    <div className='textChi'>
                        <a href='tel:0564968363'>0564 968363</a>
                    </div>
                    <b>Fax</b>
                    <div className='textChi'>
                        <a href='tel:05641835129'>0564 1835129</a>
                    </div>
                    <b>Cell</b>
                    <div className='textChi'>
                        <a href='tel:+393358191460'>+39 335 8191460</a>
                    </div>
                    <b>Mail</b>
                    <div className='textChi'>
                        <a href='mailto:info@emme-effe.it'>info@emme-effe.it</a>
                    </div>
                </div>

                <br />
                <br />
                <br />
                <b className='textChi'>Seguimi su:</b>
                <br />
                <div className='textChi'>
                    <a
                        href='https://www.facebook.com/flaviomazzini'
                        target='_blank'
                        rel='noreferrer'>
                        Facebook
                    </a>
                </div>
                <div className='textChi'>
                    <a
                        href='https://www.youtube.com/channel/UCf1_YrNRGVvfE72Sl1NrtuQ?view_as=subscriber'
                        target='_blank'
                        rel='noreferrer'>
                        Youtube
                    </a>
                </div>
                <div className='textChi'>
                    <a
                        href='https://www.linkedin.com/in/flavio-mazzini-a0b32050/'
                        target='_blank'
                        rel='noreferrer'>
                        Linkedin
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Chi;
