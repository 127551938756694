import React from "react";

function Servizi() {
    return (
        <div className='content'>
            <p>I nostri servizi:</p>
            <div className='serviziContent'>
                <div className='gridItem'>
                    <div className='titleServizi'>- Consulenza Informatica</div>
                    <p className='subtitleServizi'>Stampanti & Altri Dispositivi</p>
                    <p className='subtitleServizi'>Smartphones & Tablets</p>
                    <p className='subtitleServizi'>Risoluzioni Problemi di Rete</p>
                    <p className='subtitleServizi'>Risoluzioni Problemi con Mail & PEC</p>
                </div>

                <div className='gridItem'>
                    <div className='titleServizi'>- Assistenza Tecnica Software</div>
                    <p className='subtitleServizi'>Installazioni & Configurazioni</p>
                    <p className='subtitleServizi'>Salvataggio & Passaggio Dati</p>
                    <p className='subtitleServizi'>Pulizia & Ripristino</p>
                </div>

                <div className='gridItem'>
                    <div className='titleServizi'>- Assistenza Tecnica Hardware</div>
                    <p className='subtitleServizi'>Sostituzioni & Riparazioni</p>
                    <p className='subtitleServizi'>Acquisti </p>
                    <p className='subtitleServizi'>Attivazione Internet </p>
                    <p className='subtitleServizi'>
                        Installazione & Configurazione Centralini Voip{" "}
                    </p>
                </div>

                <div className='gridItem'>
                    <div className='titleServizi'>- Corsi Formativi</div>
                    <p className='subtitleServizi'>Word & Excel </p>
                    <p className='subtitleServizi'>Montaggio, Editing & Authoring Video </p>
                    <p className='subtitleServizi'>Preparazione Certificazioni Europee </p>
                    <p className='subtitleServizi'>Corsi Personalizzati</p>
                </div>
            </div>
        </div>
    );
}

export default Servizi;
