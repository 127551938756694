import React from "react";

function Plus() {
    return (
        <div className='plusContent'>
            <span>
                Questo sito è sviluppato in nero ed ha animazioni minimali per fare, in piccolo, del
                nostro meglio per rispettare l'ambiente.
                <br />
                <br />
            </span>
            <span>
                {" "}
                Secondo questa fonte:
                <a href='https://www.websitecarbon.com/' target='_blank' rel='noreferrer'>
                    Websitecarbon
                </a>{" "}
                il nostro sito produce solo 0.03 gg di CO2 ogni volta che qualcuno lo visita ed è
                più pulito del 97% delle pagine da loro testate!
                <br />
                <br />
            </span>
            <span>
                Sviluppato da{" "}
                <a
                    href='https://www.linkedin.com/in/giorgia-mazzini-121bb6181/'
                    target='_blank'
                    rel='noreferrer'>
                    Giorgia Mazzini
                </a>
            </span>
        </div>
    );
}

export default Plus;
